import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import {
  getTimeslotsForStationWashTypeDate,
  isAvailableAsSoonAsPossible,
} from "../api/backendCalls";
import useTitle from "../hooks/useTitle";
import {
  getDateAfterDays,
  getDateFormatted,
  getDatetimeFormatted,
  groupTimeslotsPerDay,
  isDate,
  IsOkayDateASAP,
  IsOkayDateDatetime,
  IsOkayStation,
  IsOkayWashType,
  stepsLinks,
} from "../utils/helper";
import DatesList from "../components/DatesList";
import useWindowDimensions from "../hooks/useWindowDimensions";
import NextStepReservation from "../components/NextStepReservation";
import { fullMonths, fullWeekDays } from "../utils/greekLanguage";
import MyRadioInput from "../components/MyRadioInput";

const now = new Date();

const ReserveDate = ({
  title,
  setActivePage,
  reservation,
  setReservation,
  given_date,
  nextStep,
  as_soon_as_possible,
}) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("ReserveDate");
  }, [setActivePage]);

  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [selectedDate, setSelectedDate] = useState(() => {
    let date = now;
    if (given_date && isDate(given_date)) {
      date = new Date(given_date);
    } else if (IsOkayDateDatetime(reservation)) {
      date = new Date(reservation.date.datetime);
    }
    date.setHours(0, 0, 0, 0);
    return date;
  });
  const [selectedTimeslot, setSelectedTimeslot] = useState(() => {
    if (IsOkayDateDatetime(reservation)) {
      return {
        datetime: getDatetimeFormatted(new Date(reservation.date.datetime)),
        price: reservation.date.price,
      };
    }
    return null;
  });
  const [timeslots, setTimeslots] = useState(null);
  const [minCosts, setMinCosts] = useState(null);
  const [asSoonAsPossible, setAsSoonAsPossible] = useState(() => {
    if (IsOkayDateDatetime(reservation)) return false;
    if (IsOkayDateASAP(reservation)) return true;
    return as_soon_as_possible === "true";
  });
  const [isAvailableASAP, setIsAvailableASAP] = useState(null);

  useEffect(() => {
    let elem = document.getElementById("dates-selection");
    if (asSoonAsPossible) {
      elem.classList.add("hide-smoothly");
      window.scrollTo(0, 0);
    } else {
      elem.classList.remove("hide-smoothly");
      window.scrollTo(0, 250);
    }
  }, [asSoonAsPossible]);

  useEffect(() => {
    if (!IsOkayWashType(reservation)) {
      navigate(stepsLinks["ReserveEidosPlysimatos"]);
      return;
    }
    if (!IsOkayStation(reservation)) {
      navigate(stepsLinks["ReservePratirio"]);
      return;
    }
  }, [reservation, navigate]);

  useEffect(() => {
    const getData = async (timeslots) => {
      if (!IsOkayWashType(reservation) || !IsOkayStation(reservation)) return;
      let startDate = now;
      startDate.setHours(0, 0, 0, 0);
      let endDate = getDateAfterDays(now, 10);
      endDate.setHours(0, 0, 0, 0);
      let data = await getTimeslotsForStationWashTypeDate(
        reservation.station.id,
        reservation.wash_type.id,
        startDate,
        endDate
      );
      let newTimeslots = groupTimeslotsPerDay(data, startDate, endDate);
      if (JSON.stringify(newTimeslots) !== JSON.stringify(timeslots)) {
        setTimeslots(newTimeslots);
      }

      // check if asSoonAsPossible is available
      data = await isAvailableAsSoonAsPossible(
        reservation.station.id,
        reservation.wash_type.id
      );
      if (JSON.stringify(isAvailableASAP) !== JSON.stringify(data)) {
        setIsAvailableASAP(data);
        if (data && "is_available" in data && !data.is_available) {
          setAsSoonAsPossible(false);
        }
      }
    };

    getData(timeslots);
  }, [reservation, timeslots, isAvailableASAP, asSoonAsPossible]);

  useEffect(() => {
    if (!timeslots) return;
    let newMinCosts = {};
    for (let date in timeslots) {
      if (timeslots[date].length !== 0) {
        newMinCosts[date] = Math.min(...timeslots[date].map((i) => i.price));
      }
    }
    setMinCosts(newMinCosts);
  }, [timeslots]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!timeslots || !selectedDate) return;
    // if selectedTimeslot is in selectedDate, then return
    if (
      selectedTimeslot &&
      timeslots[getDateFormatted(selectedDate)]
        .map((d) => d.datetime)
        .includes(selectedTimeslot.datetime)
    )
      return;

    if (
      getDateFormatted(selectedDate) in timeslots &&
      timeslots[getDateFormatted(selectedDate)].length > 0
    ) {
      setSelectedTimeslot(timeslots[getDateFormatted(selectedDate)][0]);
    }
  }, [selectedTimeslot, selectedDate, timeslots]);

  return (
    <div className="fccc w-100 pb-120px">
      <h2 className="font-bold font-larger step-h">Βήμα 3: Ημερομηνία & ώρα</h2>
      <div className="fccc w-100">
        <ul className="fccc select-ul">
          <li
            className={"frbc w-100 " + (!asSoonAsPossible ? "opacity-50" : "")}
            onClick={() => {
              if (
                isAvailableASAP &&
                "is_available" in isAvailableASAP &&
                isAvailableASAP.is_available &&
                "price" in isAvailableASAP
              ) {
                setAsSoonAsPossible(true);
              }
            }}
          >
            <div>
              <h3 className="font-medium font-regular">
                Το συντομότερο δυνατόν
              </h3>
              {reservation !== {} &&
              isAvailableASAP &&
              "is_available" in isAvailableASAP &&
              isAvailableASAP.is_available &&
              "price" in isAvailableASAP ? (
                <h4 className="font-small font-regular">
                  Με συνεννόηση στο κατάστημα
                </h4>
              ) : (
                <h4 className="font-small font-regular my-input-help-error">
                  Μη διαθέσιμο
                </h4>
              )}
            </div>
            <div className="frcc">
              <p className="font-medium font-bold">
                {reservation !== {} &&
                isAvailableASAP &&
                "is_available" in isAvailableASAP &&
                isAvailableASAP.is_available &&
                "price" in isAvailableASAP
                  ? `${isAvailableASAP.price} €`
                  : ""}
              </p>
              <MyRadioInput selected={asSoonAsPossible} />
            </div>
          </li>
          <li
            className={"frbc w-100 " + (asSoonAsPossible ? "opacity-50" : "")}
            onClick={() => setAsSoonAsPossible(false)}
          >
            <div>
              <h3 className="font-medium font-regular">
                Συγκεκριμένη ημερομηνία & ώρα
              </h3>
              <h4 className="font-small font-regular">
                Όποτε σε βολεύει καλύτερα
              </h4>
            </div>
            <div className="frcc">
              <p className="font-medium font-bold">
                {reservation !== {} && IsOkayWashType(reservation)
                  ? parseFloat(
                      reservation.wash_type.price_after_discounts
                    ).toFixed(0) !==
                    parseFloat(reservation.wash_type.price).toFixed(0)
                    ? `${parseFloat(
                        reservation.wash_type.price_after_discounts
                      ).toFixed(0)} - ${parseFloat(
                        reservation.wash_type.price
                      ).toFixed(0)} €`
                    : `${parseFloat(reservation.wash_type.price).toFixed(0)} €`
                  : ""}
              </p>
              <MyRadioInput selected={!asSoonAsPossible} />
            </div>
          </li>
        </ul>
        <div id="dates-selection" className="fccc dates-selection">
          <h2 className="font-bold font-large">
            {fullWeekDays[(selectedDate.getDay() + 6) % 7]},{" "}
            {selectedDate.getDate()} {fullMonths[selectedDate.getMonth()]}{" "}
            {selectedDate.getFullYear()}
          </h2>
          <DatesList
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            minDay={now}
            maxDay={getDateAfterDays(now, 10)}
            minCosts={minCosts}
            daysToDisplay={width < 380 ? 3 : width < 700 ? 4 : 5}
          />
          <div className="date-div-hr" />
          <ul className="fccc w-100">
            {timeslots ? (
              getDateFormatted(selectedDate) in timeslots &&
              timeslots[getDateFormatted(selectedDate)].length ? (
                timeslots[getDateFormatted(selectedDate)].map((timeslotDay) => {
                  return (
                    <li
                      onClick={() => setSelectedTimeslot(timeslotDay)}
                      className="frbc w-95"
                      key={timeslotDay.datetime}
                    >
                      <div className="frcc">
                        <div
                          className={
                            selectedTimeslot &&
                            selectedTimeslot.datetime === timeslotDay.datetime
                              ? "dot-div-selected"
                              : "dot-div"
                          }
                        ></div>
                        <p
                          className={
                            selectedTimeslot &&
                            selectedTimeslot.datetime === timeslotDay.datetime
                              ? "font-bold"
                              : ""
                          }
                        >
                          {timeslotDay.datetime.substring(11, 16)}
                        </p>
                      </div>
                      <p
                        className={
                          selectedTimeslot &&
                          selectedTimeslot.datetime === timeslotDay.datetime
                            ? "font-bold"
                            : ""
                        }
                      >
                        € {parseFloat(timeslotDay.price).toFixed(2)}
                      </p>
                    </li>
                  );
                })
              ) : (
                <p className="text-center font-small italic">
                  Δεν υπάρχει διαθέσιμο πλύσιμο σε αυτήν την ημερομηνία.
                </p>
              )
            ) : (
              <ReactLoading
                type="spin"
                color="#f3f3f3"
                height={80}
                width={80}
              />
            )}
          </ul>
        </div>
      </div>

      {width < 1000 ? (
        // phone view
        <NextStepReservation
          show={true}
          upRowTextMain={
            reservation !== {} &&
            IsOkayStation(reservation) &&
            IsOkayWashType(reservation)
              ? `${reservation.station.name}, ${reservation.wash_type.name}`
              : ""
          }
          downRowTextMain={
            selectedTimeslot
              ? parseFloat(selectedTimeslot.price).toFixed(2) + " €"
              : ""
          }
          onClickFunc={() =>
            nextStep(
              selectedDate,
              selectedTimeslot,
              timeslots,
              reservation,
              setReservation,
              navigate,
              asSoonAsPossible,
              isAvailableASAP && "price" in isAvailableASAP
                ? isAvailableASAP["price"]
                : null
            )
          }
          disabledButton={!selectedTimeslot && !asSoonAsPossible}
        />
      ) : (
        // desktop view
        <NextStepReservation
          show={true}
          upRowTextMain={
            reservation !== {} &&
            IsOkayStation(reservation) &&
            IsOkayWashType(reservation)
              ? `${reservation.station.name}, ${reservation.wash_type.name}`
              : ""
          }
          downRowTextMain={
            selectedTimeslot
              ? parseFloat(selectedTimeslot.price).toFixed(2) + " €"
              : ""
          }
          nextButtonText={"Επόμενο"}
          onClickFunc={() =>
            nextStep(
              selectedDate,
              selectedTimeslot,
              timeslots,
              reservation,
              setReservation,
              navigate,
              asSoonAsPossible,
              isAvailableASAP && "price" in isAvailableASAP
                ? isAvailableASAP["price"]
                : null
            )
          }
          disabledButton={!selectedTimeslot && !asSoonAsPossible}
        />
      )}
    </div>
  );
};

export default ReserveDate;
