import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import useTitle from "../hooks/useTitle";
import { getStationsGivenWashType, getWashTypes } from "../api/backendCalls";
import {
  getDateAfterDays,
  getDateFormatted,
  stepsLinks,
  stepsLinksSkipPratirio,
} from "../utils/helper";

const Reserve = ({ title, setActivePage, setReservation, reservationFlow }) => {
  useTitle({ title });

  const { plysimo, pratirio, date } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      let washTypeID = parseInt(plysimo);
      let stationID = parseInt(pratirio);
      let day = new Date(date);
      let reservation = {};

      // wash types ...
      let washTypes = await getWashTypes();
      if (washTypes === null) {
        navigate("/not-found/");
        return;
      }

      if (!washTypes.map((i) => parseInt(i.id)).includes(washTypeID)) {
        navigate("/not-found/");
        return;
      } else {
        for (let i = 0; i < washTypes.length; i++) {
          if (parseInt(washTypes[i].id) === washTypeID) {
            reservation.wash_type = washTypes[i];
            break;
          }
        }
      }

      // stations ...
      let stations = await getStationsGivenWashType(washTypeID);
      if (stations === null) {
        setReservation(reservation);
        navigate(stepsLinks["ReservePratirio"]);
        return;
      }

      if (!stations.map((i) => parseInt(i.id)).includes(stationID)) {
        setReservation(reservation);
        navigate(stepsLinks["ReservePratirio"]);
        return;
      } else {
        for (let i = 0; i < stations.length; i++) {
          if (parseInt(stations[i].id) === stationID) {
            reservation.station = stations[i];
            break;
          }
        }
      }

      setReservation(reservation);
      // day ...
      if (
        day.getTime() < getDateAfterDays(new Date(), 10).getTime() &&
        day.getTime() > getDateAfterDays(new Date(), 1).getTime()
      ) {
        navigate(
          stepsLinks["ReserveDate"] + "?given_date=" + getDateFormatted(day)
        );
      } else if (reservationFlow === "reserve") {
        navigate(stepsLinks["ReserveDate"]);
      } else {
        navigate(
          stepsLinksSkipPratirio["ReserveDate"] + "?as_soon_as_possible=true"
        );
      }
    };

    getData();
  }, [plysimo, pratirio, date, navigate, reservationFlow, setReservation]);

  useEffect(() => {
    setActivePage("Reserve");
  }, [setActivePage]);

  return (
    <>
      <ReactLoading type="spin" color="#f3f3f3" height={80} width={80} />
    </>
  );
};

export default Reserve;
