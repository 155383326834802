const MyTextArea = ({ value, setValue, error, placeholder }) => {
  return (
    <textarea
      className="my-text-area"
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      rows="5"
    ></textarea>
  );
};

export default MyTextArea;
