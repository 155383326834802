import { useEffect } from "react";
import { Link } from "react-router-dom";
import useTitle from "../hooks/useTitle";

const NotFound = ({ title, setActivePage }) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("NotFound");
  }, [setActivePage]);

  return (
    <div className="full-page fccc w-100">
      <h2 className="font-bold font-large text-center">
        Συγγνώμη, αλλά... η σελίδα δεν υπάρχει.
      </h2>
      <p className="text-center">
        Πατήστε <Link to="/">εδώ</Link> για να επιστρέψετε στην αρχική.
      </p>
    </div>
  );
};

export default NotFound;
