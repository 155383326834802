import { Outlet } from "react-router-dom";
import HeaderReservation from "../components/HeaderReservation";

const LayoutReservation = ({
  activePage,
  currentStep,
  setCurrentStep,
  reservation,
  stepsIncluded,
  reserveFlow,
}) => {
  return (
    <>
      <HeaderReservation
        activePage={activePage}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        reservation={reservation}
        stepsIncluded={stepsIncluded}
        reserveFlow={reserveFlow}
      />
      <main className="content fccc">
        <Outlet />
      </main>
    </>
  );
};

export default LayoutReservation;
