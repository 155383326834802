import { useEffect } from "react";
import SuccessPage from "../components/SuccessPage";
import useTitle from "../hooks/useTitle";

const ReviewSuccess = ({ title, setActivePage }) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("ReviewSuccess");
  }, [setActivePage]);

  return (
    <SuccessPage
      h2Text="Η κριτική καταγράφηκε επιτυχώς. Ευχαριστούμε!"
      extraText={
        <p className="text-center">Μπορείς να κλείσεις την εφαρμογή.</p>
      }
    />
  );
};

export default ReviewSuccess;
