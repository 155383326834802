import {
  checkReservationDate,
  checkReservationItem,
  getDateFormatted,
  stepsLinks,
  stepsLinksSkipPratirio,
} from "./helper";

export const reservationGoToPratirio = (
  selectedWashType,
  washTypes,
  reservation,
  setReservation,
  navigate,
  displayedIsRecommended,
) => {
  let { err } = checkReservationItem(selectedWashType, washTypes, "wash_type");
  if (err !== null) {
    console.log(err);
    return;
  }

  reservation["displayed_is_recommended"] = displayedIsRecommended;
  reservation["wash_type"] = selectedWashType;
  setReservation(reservation);

  navigate(stepsLinks["ReservePratirio"]);
};

export const reservationGoToDate = (
  selectedStation,
  stations,
  reservation,
  setReservation,
  navigate
) => {
  let { err } = checkReservationItem(selectedStation, stations, "station");
  if (err !== null) {
    console.log(err);
    return;
  }

  reservation["station"] = selectedStation;
  setReservation(reservation);

  navigate(stepsLinks["ReserveDate"]);
};

export const reservationGoToInformation = (
  selectedDate,
  selectedTimeslot,
  timeslots,
  reservation,
  setReservation,
  navigate,
  asSoonAsPossible,
  asSoonAsPossiblePrice
) => {
  if (asSoonAsPossible) {
    reservation["date"] = {
      as_soon_as_possible: true,
      price: asSoonAsPossiblePrice,
    };
    setReservation(reservation);
    navigate(stepsLinks["ReserveInformation"]);
    return;
  }
  if (!selectedDate || !selectedTimeslot) return;
  let { err } = checkReservationDate(
    selectedTimeslot,
    timeslots[getDateFormatted(selectedDate)]
  );
  if (err !== null) {
    console.log(err);
    return;
  }

  let datetime = new Date(selectedDate);
  datetime.setHours(
    parseInt(selectedTimeslot.datetime.substring(11, 13)),
    parseInt(selectedTimeslot.datetime.substring(14, 16))
  );
  reservation["date"] = {
    as_soon_as_possible: false,
    datetime: datetime,
    price: selectedTimeslot.price,
  };
  setReservation(reservation);

  navigate(stepsLinks["ReserveInformation"]);
};

export const reservation2GoToDate = (
  selectedWashType,
  washTypes,
  reservation,
  setReservation,
  navigate,
  displayedIsRecommended,
) => {
  let { err } = checkReservationItem(selectedWashType, washTypes, "wash_type");
  if (err !== null) {
    console.log(err);
    return;
  }

  reservation["displayed_is_recommended"] = displayedIsRecommended;
  reservation["wash_type"] = selectedWashType;
  setReservation(reservation);

  navigate(stepsLinksSkipPratirio["ReserveDate"]);
};

export const reservation2GoToInformation = (
  selectedDate,
  selectedTimeslot,
  timeslots,
  reservation,
  setReservation,
  navigate,
  asSoonAsPossible,
  asSoonAsPossiblePrice
) => {
  if (asSoonAsPossible) {
    reservation["date"] = {
      as_soon_as_possible: true,
      price: asSoonAsPossiblePrice,
    };
    setReservation(reservation);
    navigate(stepsLinksSkipPratirio["ReserveInformation"]);
    return;
  }
  if (!selectedDate || !selectedTimeslot) return;
  let { err } = checkReservationDate(
    selectedTimeslot,
    timeslots[getDateFormatted(selectedDate)]
  );
  if (err !== null) {
    console.log(err);
    return;
  }

  let datetime = new Date(selectedDate);
  datetime.setHours(
    parseInt(selectedTimeslot.datetime.substring(11, 13)),
    parseInt(selectedTimeslot.datetime.substring(14, 16))
  );
  reservation["date"] = {
    as_soon_as_possible: false,
    datetime: datetime,
    price: selectedTimeslot.price,
  };
  setReservation(reservation);

  navigate(stepsLinksSkipPratirio["ReserveInformation"]);
};
