import { useEffect } from "react";
import useTitle from "../hooks/useTitle";

const ReserveFail = ({ title, setActivePage }) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("Reserve");
  }, [setActivePage]);

  return (
    <>
      <h1>ReserveFail</h1>
    </>
  );
};

export default ReserveFail;
