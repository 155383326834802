const Footer = () => {
  return (
    <footer className="w-100 footer fccc p-20px">
      <div className="frbc">
        <p>
          © 2023 created by{" "}
          <a
            className="font-medium font-bold"
            href="https://www.lucidity.gr"
            target="_blank"
            rel="noreferrer"
          >
            Lucidity
          </a>
        </p>
        <p>
          <a
            className="font-medium font-medium italic blue-text-color"
            href="https://www.mparatsas.gr"
            target="_blank"
            rel="noreferrer"
          >
            www.mparatsas.gr
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
