import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";

import useTitle from "../hooks/useTitle";
import { stepsLinks } from "../utils/helper";

const ReserveRedirectParams = ({
  title,
  setActivePage,
  reservation,
  setReservation,
  reservationFlow,
}) => {
  useTitle({ title });

  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      let booked_from_code = searchParams.get("booked_from_code");
      booked_from_code = booked_from_code.replace("/", "");
      if (booked_from_code !== null) {
        reservation["booked_from_code"] = booked_from_code;
      }
      setReservation(reservation);
      navigate(stepsLinks["ReserveEidosPlysimatos"]);
    };

    getData();
  }, [reservation, searchParams, navigate, reservationFlow, setReservation]);

  useEffect(() => {
    setActivePage("Reserve");
  }, [setActivePage]);

  return (
    <>
      <ReactLoading type="spin" color="#f3f3f3" height={80} width={80} />
    </>
  );
};

export default ReserveRedirectParams;
