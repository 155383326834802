export const NUMBERS_ONLY_REGEX = /^\d+$/;
export const TEN_NUMBERS_ONLY_REGEX = /^\d{10}$/;
export const UP_TO_TEN_NUMBERS_REGEX = /^\d{0,10}$/;
export const GREEK_OR_ENGLISH_REGEX = /^[A-Za-zΑ-Ωα-ωίΊϊΪΐόΌάΆέΈύΎϋΫΰήΉώΏ/ ]+$/;
export const GREEK_OR_ENGLISH_REGEX_0_OR_MORE =
  /^[A-Za-zΑ-Ωα-ωίΊϊΪΐόΌάΆέΈύΎϋΫΰήΉώΏ/ ]*$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const UP_TO_TEN_CHARACTERS_REGEX =
  /^[A-Za-zΑ-Ωα-ωίΊϊΪΐόΌάΆέΈύΎϋΫΰήΉώΏ/ /-0-9]{0,10}$/;
