import MyButton from "./MyButton";
import useWindowDimensions from "../hooks/useWindowDimensions";

const NextStepReservation = ({
  show,
  upRowTextMain,
  upRowTextSecondary,
  downRowTextMain,
  downRowTextSecondary,
  nextButtonText,
  onClickFunc,
  disabledButton,
}) => {
  const { width } = useWindowDimensions();
  return (
    <>
      <div
        className={
          "reservation-step frbc " + (show ? "" : "reservation-step-no-display")
        }
      >
        <div className="fccs">
          <div className="frsc">
            {upRowTextMain ? (
              <h3 className="font-regular font-medium">{upRowTextMain}</h3>
            ) : null}
            {upRowTextSecondary ? (
              <h4 className="font-regular font-medium font-bold">
                {upRowTextSecondary}
              </h4>
            ) : null}
          </div>
          <div className="frcc">
            {downRowTextSecondary ? (
              <h6 className="font-light font-small italic">
                {downRowTextSecondary}
              </h6>
            ) : null}
            {downRowTextMain ? (
              <h5 className="font-regular font-small">{downRowTextMain}</h5>
            ) : null}
          </div>
        </div>
        {width < 1000 ? (
          <MyButton
            onClickFunc={onClickFunc}
            isImage={true}
            text={
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/right-arrow-white.png`}
                className="w-22px"
                alt="next"
              />
            }
            primary={true}
          />
        ) : (
          <MyButton
            onClickFunc={onClickFunc}
            text={nextButtonText}
            medium={true}
            primary={true}
            disabled={disabledButton}
          />
        )}
      </div>
    </>
  );
};

export default NextStepReservation;
