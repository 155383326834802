import Carousel from "react-bootstrap/Carousel";
import "./components.css";

const MyCarousel = () => {
  return (
    <div className="my-carousel w-100">
      <Carousel>
        <Carousel.Item>
          <img
            className="w-100 img-my-carousel"
            src={`${process.env.PUBLIC_URL}/img/plysimo1.jpg`}
            alt="Καύσιμα Μπαράτσας - Πλύσιμο αυτοκινήτου"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100"
            src={`${process.env.PUBLIC_URL}/img/plysimo2.jpg`}
            alt="Καύσιμα Μπαράτσας - Πλύσιμο αυτοκινήτου"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default MyCarousel;
