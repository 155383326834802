import { useEffect } from "react";
import MyCarousel from "../components/MyCarousel";
import MyButton from "../components/MyButton";
import useTitle from "../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import { stepsLinks } from "../utils/helper";

const Home = ({ title, setActivePage }) => {
  useTitle({ title });

  const navigate = useNavigate();

  useEffect(() => {
    setActivePage("Home");
  }, [setActivePage]);

  return (
    <>
      <MyCarousel />
      <h1 className="font-larger font-bold text-center text-wrap p-20px">
        Κλείσε ραντεβού για πλύσιμο αυτοκινήτου
      </h1>

      <section className="homepage-icons">
        <div className="fccc">
          <img
            className="d-block w-70px"
            src={`${process.env.PUBLIC_URL}/img/icons/price-tag.png`}
            alt="Καύσιμα Μπαράτσας - Πλύσιμο αυτοκινήτου"
          />
          <h3>Ανταγωνιστικές τιμές</h3>
          <p className="italic-p">
            Απλώς κλείνοντας ραντεβού
            <br /> αρκετές μέρες πριν
          </p>
        </div>
        <div className="fccc">
          <img
            className="d-block w-70px"
            src={`${process.env.PUBLIC_URL}/img/icons/appointment.png`}
            alt="Καύσιμα Μπαράτσας - Πλύσιμο αυτοκινήτου"
          />
          <h3>Όποτε θες</h3>
          <p className="italic-p">
            Κλείσε ραντεβού την
            <br /> ώρα που σε βολεύει καλύτερα
          </p>
        </div>
        <div className="fccc">
          <img
            className="d-block w-70px"
            src={`${process.env.PUBLIC_URL}/img/icons/star.png`}
            alt="Καύσιμα Μπαράτσας - Πλύσιμο αυτοκινήτου"
          />
          <h3>Υψηλή ποιότητα</h3>
          <p className="italic-p">
            Το καλύτερο πλύσιμο
            <br /> αυτοκίνητων στα Ιωάννινα
          </p>
        </div>
      </section>

      <MyButton
        text="Κλείσε Ραντεβού"
        primary={true}
        big={true}
        extraClasses={["m-20px"]}
        onClickFunc={() => navigate(stepsLinks["ReserveEidosPlysimatos"])}
      />

      <img
        className="w-100 m-20px"
        src={`${process.env.PUBLIC_URL}/img/line.png`}
        alt="Μπαράτσας Καύσιμα"
      />

      <div className="frac w-100 m-20px">
        <div className="fcac w-400px">
          <h3 className="text-center p-20px font-bold">
            Κλείσε online το ραντεβού για το πλύσιμο του αυτοκινήτου σου
            κερδίζοντας μέχρι και 50% έκπτωση σε σύγκριση με την αρχική τιμή
            πλυσίματος!
          </h3>
          <p className="italic-p p-20px">
            Ορισμένες τιμές πλυσίματος που παρουσιάζονται σε αυτήν την
            ιστοσελίδα είναι αρκετά χαμηλότερες σε σύγκριση με τις τιμές
            πλυσίματος που προσφέρονται κατά την απευθείας επίσκεψη στα πρατήρια
            Μπαράτσας.
          </p>
        </div>
        <img
          className="w-140px phone-no-display"
          src={`${process.env.PUBLIC_URL}/img/app-image.png`}
          alt="Μπαράτσας Καύσιμα"
        />
      </div>

      <p className="font-medium font-regular text-center text-wrap p-20px mw-700px lh-23px">
        Για περισσότερες πληροφορίες σχετικά με την εταιρία, μπορείτε να
        επισκεφτείτε την ιστοσελίδα{" "}
        <a
          className="blue-text-color"
          href="https://www.mparatsas.gr"
          target="_blank"
          rel="noreferrer"
        >
          www.mparatsas.gr
        </a>
        .
      </p>
    </>
  );
};

export default Home;
