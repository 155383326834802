import { getDatetimeFormattedForBackend, strToDate } from "../utils/helper";
import axios from "./axios";
import { paymentMethodMapper } from "./mapper";

const urls = {
  create_review: "/create_review",
  create_wash_reservation: "/create_wash_reservation",
  create_wash_reservation_asap: "/create_wash_reservation_asap",
  list_wash_types: "/list_wash_types",
  list_stations: "/list_stations",
  is_available_asap: "/is_available_asap",
  list_available_timeslots: "/list_available_timeslots",
};
const unauthorizedHeaders = {
  headers: { "Content-Type": "application/json" },
};

export const getWashTypes = async () => {
  try {
    const response = await axios.post(
      urls.list_wash_types,
      JSON.stringify({}),
      unauthorizedHeaders
    );
    if (response && response.data) return response.data;
  } catch (err) {
    console.log("error 1", err);
  }
  return null;
};

export const getStationsGivenWashType = async (washTypeId) => {
  try {
    const response = await axios.post(
      urls.list_stations,
      JSON.stringify({
        wash_type_id: washTypeId,
      }),
      unauthorizedHeaders
    );
    if (response && response.data) return response.data;
  } catch (err) {
    console.log("error 2", err);
  }
  return null;
};

export const getStations = async () => {
  try {
    const response = await axios.post(
      urls.list_stations,
      JSON.stringify({}),
      unauthorizedHeaders
    );
    if (response && response.data) return response.data;
  } catch (err) {
    console.log("error 7", err);
  }
  return null;
};

export const getTimeslotsForStationWashTypeDate = async (
  station_id,
  wash_type_id,
  start,
  end
) => {
  try {
    const response = await axios.post(
      urls.list_available_timeslots,
      JSON.stringify({
        station_id,
        wash_type_id,
        start_at: getDatetimeFormattedForBackend(start),
        end_at: getDatetimeFormattedForBackend(end),
      }),
      unauthorizedHeaders
    );
    if (response && response.data) return response.data;
  } catch (err) {
    console.log("error 2", err);
  }
  return null;
};

export const createReservation = async (reservation) => {
  let startAt = new Date();
  let endpoint = urls.create_wash_reservation_asap;
  if (!reservation.date.as_soon_as_possible) {
    startAt = reservation.date.datetime;
    endpoint = urls.create_wash_reservation;
  }
  if (typeof startAt !== typeof new Date()) {
    startAt = strToDate(startAt);
  }

  let createReq = {
    reserved_at: getDatetimeFormattedForBackend(new Date()),
    station_id: reservation.station.id,
    wash_type_id: reservation.wash_type.id,
    name: reservation.information.name,
    phone: reservation.information.phone,
    start_at: getDatetimeFormattedForBackend(startAt),
    final_price: parseFloat(reservation.displayed_final_price).toFixed(2),
    email: reservation.information.email,
    payment_method:
      paymentMethodMapper[reservation.information.payment_method.name],
    comments: reservation.information.comments,
    displayed_is_recommended: reservation.displayed_is_recommended,
  };

  if ("booked_from_code" in reservation) {
    createReq["booked_from_code"] = reservation["booked_from_code"];
  }

  try {
    const response = await axios.post(
      endpoint,
      JSON.stringify(createReq),
      unauthorizedHeaders
    );
    if (response && response.data) return response.data;
  } catch (err) {
    console.log("error 4", err);
  }
  return null;
};

export const createReview = async (review) => {
  try {
    const response = await axios.post(
      urls.create_review,
      JSON.stringify({
        hash: review.id.toString(),
        stars: review.stars,
        comments: review.comments.join(", ") + " | " + review.extra_comments,
      }),
      unauthorizedHeaders
    );
    if (response && response.data) return response.data;
  } catch (err) {
    console.log("error 5", err);
  }
  return null;
};

export const isAvailableAsSoonAsPossible = async (station_id, wash_type_id) => {
  try {
    const response = await axios.post(
      urls.is_available_asap,
      JSON.stringify({
        station_id,
        wash_type_id,
      }),
      unauthorizedHeaders
    );
    if (response && response.data) return response.data;
  } catch (err) {
    console.log("error 6", err);
  }
  return null;
};
