import { useState } from "react";

const MyInput = ({
  type,
  value,
  setValue,
  error,
  setError,
  helpText,
  placeholder,
  allowedCharactersPatern,
}) => {
  const [help, setHelp] = useState(false);
  const onChangeFunc = (e) => {
    if (!allowedCharactersPatern) {
      setValue(e.target.value);
      setError(false);
      return;
    }
    if (allowedCharactersPatern.test(e.target.value)) {
      setValue(e.target.value);
      setError(false);
      setHelp(false);
    } else {
      setHelp(true);
    }
  };

  return (
    <>
      <input
        type={type}
        className={"my-input " + (error ? "my-input-error" : "")}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChangeFunc(e)}
      />
      <p className={"my-input-help " + (error ? "my-input-help-error" : "")}>
        {(error || help) && helpText ? helpText : ""}
      </p>
    </>
  );
};

export default MyInput;
