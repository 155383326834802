function check(obj, c) {
  if (obj !== undefined && obj === true) {
    return ` ${c}`;
  }
  return "";
}

const MyButton = ({
  text,
  onClickFunc,
  primary,
  secondary,
  isImage,
  big,
  medium,
  small,
  extraClasses,
  disabled,
}) => {
  return (
    <button
      onClick={onClickFunc}
      className={
        "fccc my-button" +
        check(primary, "button-primary") +
        check(secondary, "button-secondary") +
        check(isImage, "button-image") +
        check(big, "button-big") +
        check(medium, "button-medium") +
        check(small, "button-small") +
        check(disabled, "button-disabled") +
        (extraClasses !== undefined ? " " + extraClasses.join(" ") : "")
      }
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default MyButton;
