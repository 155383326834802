import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import useTitle from "../hooks/useTitle";
import { getStations } from "../api/backendCalls";
import { stepsLinks, stepsLinksSkipPratirio } from "../utils/helper";

const ReserveHelperUrl = ({
  title,
  setActivePage,
  setReservation,
  reservationFlow,
}) => {
  useTitle({ title });

  const { pratirio } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      let stationHash = pratirio.toString();
      let reservation = {};

      // stations ...
      let stations = await getStations();
      if (stations === null) {
        setReservation(reservation);
        navigate(stepsLinks["ReservePratirio"]);
        return;
      }

      if (!stations.map((i) => i.url_hash).includes(stationHash)) {
        setReservation(reservation);
        navigate(stepsLinks["ReservePratirio"]);
        return;
      } else {
        for (let i = 0; i < stations.length; i++) {
          if (stations[i].url_hash === stationHash) {
            reservation.station = stations[i];
            break;
          }
        }
      }

      setReservation(reservation);
      navigate(stepsLinksSkipPratirio["ReserveEidosPlysimatos"]);
    };

    getData();
  }, [pratirio, navigate, reservationFlow, setReservation]);

  useEffect(() => {
    setActivePage("Reserve");
  }, [setActivePage]);

  return (
    <>
      <ReactLoading type="spin" color="#f3f3f3" height={80} width={80} />
    </>
  );
};

export default ReserveHelperUrl;
