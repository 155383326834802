import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import LayoutReservation from "./components/LayoutReservation";
import Reserve from "./pages/Reserve";
import Review from "./pages/Review";
import ReserveEidosPlysimatos from "./pages/ReserveEidosPlysimatos";
import ReservePratirio from "./pages/ReservePratirio";
import ReserveDate from "./pages/ReserveDate";
import ReserveInformation from "./pages/ReserveInformation";
import ReserveSuccess from "./pages/ReserveSuccess";
import ReserveFail from "./pages/ReserveFail";
import NotFound from "./pages/NotFound";
import Terms from "./pages/Terms";
import { stepsLinks, stepsLinksSkipPratirio } from "./utils/helper";
import useLocalStorage from "./hooks/useLocalStorage";
import ReviewSuccess from "./pages/ReviewSuccess";
import { useQuery } from "./hooks/useQuery";
import {
  reservationGoToDate,
  reservation2GoToDate,
  reservation2GoToInformation,
  reservationGoToInformation,
  reservationGoToPratirio,
} from "./utils/nextStepFunctions";
import ReserveHelperUrl from "./pages/ReserveHelperUrl";
import ReserveRedirectParams from "./pages/ReserveRedirectParams";

function App() {
  const query = useQuery();
  const [activePage, setActivePage] = useState(null);
  const [reservation, setReservation] = useLocalStorage("reservation", {});
  const [currentStep, setCurrentStep] = useState("ReserveEidosPlysimatos");

  return (
    <Routes>
      <Route
        path="/"
        element={<Layout footer={true} activePage={activePage} />}
      >
        <Route
          path="/"
          element={
            <Home
              setActivePage={setActivePage}
              title="Πλύσιμο Αυτοκινήτου Ιωάννινα"
            />
          }
        />
        <Route
          path="/terms"
          element={<Terms setActivePage={setActivePage} title="Όροι χρήσης" />}
        />
      </Route>

      {/* Normal reservation flow */}
      <Route
        path="/reserve/"
        element={
          <LayoutReservation
            activePage={activePage}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            reservation={reservation}
            reserveFlow="reserve"
            stepsIncluded={[
              "ReserveEidosPlysimatos",
              "ReservePratirio",
              "ReserveDate",
              "ReserveInformation",
            ]}
          />
        }
      >
        <Route
          path={stepsLinks["ReserveEidosPlysimatos"]}
          element={
            <ReserveEidosPlysimatos
              setActivePage={setActivePage}
              title="Επιλογή Είδους Πλυσίματος"
              reservation={reservation}
              setReservation={setReservation}
              nextStep={reservationGoToPratirio}
            />
          }
        />
        <Route
          path={stepsLinks["ReservePratirio"]}
          element={
            <ReservePratirio
              setActivePage={setActivePage}
              title="Επιλογή Πρατηρίου"
              reservation={reservation}
              setReservation={setReservation}
              nextStep={reservationGoToDate}
            />
          }
        />
        <Route
          path={stepsLinks["ReserveDate"]}
          element={
            <ReserveDate
              setActivePage={setActivePage}
              title="Επιλογή Ημερομηνίας"
              reservation={reservation}
              setReservation={setReservation}
              given_date={query.get("given_date")}
              as_soon_as_possible={query.get("as_soon_as_possible")}
              nextStep={reservationGoToInformation}
            />
          }
        />
        <Route
          path={stepsLinks["ReserveInformation"]}
          element={
            <ReserveInformation
              setActivePage={setActivePage}
              title="Συμπλήρωση Στοιχείων"
              reservation={reservation}
              setReservation={setReservation}
            />
          }
        />
        <Route
          path="/reserve/:plysimo/:pratirio/:date/"
          element={
            <Reserve
              setActivePage={setActivePage}
              title="Κράτηση πλυσίματος αυτοκινήτου"
              reservation={reservation}
              setReservation={setReservation}
              reservationFlow="reserve"
            />
          }
        />
      </Route>

      {/* Reservation flow that skips ReservePratirio step,
          which could be  used directly in stations for fast reservation */}
      <Route
        path="/reserve-2/"
        element={
          <LayoutReservation
            activePage={activePage}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            reservation={reservation}
            reserveFlow="reserve-2"
            stepsIncluded={[
              "ReserveEidosPlysimatos",
              "ReserveDate",
              "ReserveInformation",
            ]}
          />
        }
      >
        <Route
          path={stepsLinksSkipPratirio["ReserveEidosPlysimatos"]}
          element={
            <ReserveEidosPlysimatos
              setActivePage={setActivePage}
              title="Επιλογή Είδους Πλυσίματος"
              reservation={reservation}
              setReservation={setReservation}
              nextStep={reservation2GoToDate}
            />
          }
        />
        <Route
          path={stepsLinksSkipPratirio["ReserveDate"]}
          element={
            <ReserveDate
              setActivePage={setActivePage}
              title="Επιλογή Ημερομηνίας"
              reservation={reservation}
              setReservation={setReservation}
              given_date={query.get("given_date")}
              as_soon_as_possible={query.get("as_soon_as_possible")}
              nextStep={reservation2GoToInformation}
            />
          }
        />
        <Route
          path={stepsLinksSkipPratirio["ReserveInformation"]}
          element={
            <ReserveInformation
              setActivePage={setActivePage}
              title="Συμπλήρωση Στοιχείων"
              reservation={reservation}
              setReservation={setReservation}
            />
          }
        />
        <Route
          path="/reserve-2/:plysimo/:pratirio/:date/"
          element={
            <Reserve
              setActivePage={setActivePage}
              title="Κράτηση πλυσίματος αυτοκινήτου"
              reservation={reservation}
              setReservation={setReservation}
              reservationFlow="reserve-2"
            />
          }
        />
      </Route>
      <Route
        path="/reserve-3/:pratirio"
        element={
          <ReserveHelperUrl
            setActivePage={setActivePage}
            title="Κράτηση πλυσίματος αυτοκινήτου"
            setReservation={setReservation}
            reservationFlow="reserve-2"
          />
        }
      />
      <Route
        path="/reserve-4"
        element={
          <ReserveRedirectParams
            setActivePage={setActivePage}
            title="Κράτηση πλυσίματος αυτοκινήτου"
            reservation={reservation}
            setReservation={setReservation}
            reservationFlow="reserve-1"
          />
        }
      />

      <Route
        path="/"
        element={<Layout footer={false} activePage={activePage} />}
      >
        <Route
          path="/reserve/success/"
          element={
            <ReserveSuccess
              setActivePage={setActivePage}
              title="Επιτυχής κράτηση"
            />
          }
        />
        <Route
          path="/reserve/fail/"
          element={
            <ReserveFail
              setActivePage={setActivePage}
              title="Ανεπιτυχής κράτηση"
            />
          }
        />
        <Route
          path="/review/:id/:given_star"
          element={
            <Review
              setActivePage={setActivePage}
              title="Πες μας τη γνώμη σου"
            />
          }
        />
        <Route
          path="/review/:id"
          element={
            <Review
              setActivePage={setActivePage}
              title="Πες μας τη γνώμη σου"
            />
          }
        />
        <Route
          path="/review/success/"
          element={
            <ReviewSuccess
              setActivePage={setActivePage}
              title="Επιτυχής καταχώρηση κριτικής"
            />
          }
        />
        <Route
          path="*"
          element={
            <NotFound
              setActivePage={setActivePage}
              title="Η σελίδα δεν βρέθηκε"
            />
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
