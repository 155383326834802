import "./components.css";

const MyRadioInput = ({ selected }) => {
  return (
    <div
      className={selected ? "my-radio-input-selected" : "my-radio-input"}
    ></div>
  );
};

export default MyRadioInput;
