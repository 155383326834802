const MyCheckBox = ({
  value,
  setValue,
  label,
  error,
  setError,
  help,
  helpText,
  name,
}) => {
  const onChangeFunc = (e) => {
    setValue(e.target.checked);
    setError(false);
  };

  // useEffect(() => {
  //   document.getElementById(name).checked = value;
  // }, [value]);

  return (
    <div className="w-100 fcss my-checkbox">
      <div className="frcs">
        <input
          type="checkbox"
          name={name}
          id={name}
          value={value}
          onChange={(e) => onChangeFunc(e)}
        />
        <label htmlFor={name}>{label}</label>
      </div>
      <p className={"my-input-help " + (error ? "my-input-help-error" : "")}>
        {(error || help) && helpText ? helpText : ""}
      </p>
    </div>
  );
};

export default MyCheckBox;
