import "./components.css";
import { Link } from "react-router-dom";

const Header = ({ activePage }) => {
  return (
    <header className="header frcc w-100 h-80px">
      <div className="header-main frbc">
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/img/logo.png`}
            alt="Μπαράτσας Καύσιμα"
          />
        </Link>
        <ul className="header-nav frcc">
          <li>
            <Link
              className={
                activePage === "Reserve"
                  ? "lh-80px item-active font-bold"
                  : "lh-80px"
              }
              to="/reserve/eidos-plysimatos/"
            >
              Ραντεβού
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
