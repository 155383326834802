import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import MyButton from "../components/MyButton";
import MyTextArea from "../components/MyTextArea";
import useTitle from "../hooks/useTitle";
import { removeItemOnce } from "../utils/helper";
import { createReview } from "../api/backendCalls";

const suggestedComments = {
  1: [
    "Πολύ αργό πλύσιμο",
    "Δεν τηρήθηκε η ώρα ραντεβού",
    "Αγενές προσωπικό",
    "Μη ποιοτικό πλύσιμο",
    "Κακή σχέση ποιότητας τιμής",
  ],
  2: [
    "Πολύ αργό πλύσιμο",
    "Δεν τηρήθηκε η ώρα ραντεβού",
    "Αγενές προσωπικό",
    "Μη ποιοτικό πλύσιμο",
    "Κακή σχέση ποιότητας τιμής",
  ],
  3: [
    "Πολύ αργό πλύσιμο",
    "Δεν τηρήθηκε η ώρα ραντεβού",
    "Αγενές προσωπικό",
    "Μη ποιοτικό πλύσιμο",
    "Κακή σχέση ποιότητας τιμής",
  ],
  4: [
    "Γρήγορο πλύσιμο",
    "Ευγενικός υπάλληλος",
    "Ποιοτικό πλύσιμο",
    "Καλή σχέση ποιότητας τιμής",
    "Δόθηκε προσοχή στη λεπτομέρεια",
    "Δεν τηρήθηκε η ώρα ραντεβού",
  ],
  5: [
    "Γρήγορο πλύσιμο",
    "Ευγενικός υπάλληλος",
    "Ποιοτικό πλύσιμο",
    "Καλή σχέση ποιότητας τιμής",
    "Δόθηκε προσοχή στη λεπτομέρεια",
  ],
};

const Review = ({ title, setActivePage }) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("Review");
  }, [setActivePage]);

  const { id, given_star } = useParams();

  const navigate = useNavigate();

  const [stars, setStars] = useState(() => {
    if (
      given_star !== undefined &&
      given_star !== null &&
      given_star >= 1 &&
      given_star <= 5
    ) {
      return parseInt(given_star);
    } else {
      return 0;
    }
  });
  const [hoverStars, setHoverStars] = useState(0);
  const [comments, setComments] = useState([]);
  const [extraComments, setExtraComments] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleCommentClick = (item) => {
    let newComments = JSON.parse(JSON.stringify(comments));
    if (comments.includes(item)) {
      newComments = removeItemOnce(newComments, item);
    } else {
      newComments.push(item);
    }
    setComments(newComments);
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    let err = await createReview({
      id: id,
      stars: stars,
      comments: comments,
      extra_comments: extraComments,
    });
    if (err !== null) {
      console.log("error");
      return;
    }
    navigate("/review/success/");
  };

  useEffect(() => {
    setComments([]);
  }, [stars]);

  return (
    <div className="fccc w-100">
      <h2 className="p-5 page-title">Πως ήταν το πλύσιμο;</h2>
      <p className="font-medium">Η γνώμη σου μετράει! Άσε μία κριτική.</p>
      <div className="p-5 frcc stars-review">
        {[1, 2, 3, 4, 5].map((i) => {
          return (
            <img
              key={i}
              className={
                "w-40px m-2 hover-pointer " +
                (stars >= i ? "star-selected" : "star-not-selected ") +
                (hoverStars <= i ? "" : "hover-star")
              }
              onMouseOver={() => setHoverStars(i + 1)}
              onMouseOut={() => setHoverStars(0)}
              src={`${process.env.PUBLIC_URL}/img/star-selected.png`}
              alt="star selected"
              onClick={() => setStars(i)}
            />
          );
        })}
      </div>
      <div className="frcc f-wrap m-2 mw-600px">
        {stars > 0 &&
          stars < 6 &&
          suggestedComments[stars].map((item) => {
            return (
              <div
                onClick={() => handleCommentClick(item)}
                key={item}
                className={
                  "hover-pointer review-comment m-2 " +
                  (comments.includes(item) ? "review-comment-selected" : "")
                }
              >
                {item}
              </div>
            );
          })}
      </div>
      <div className="frcc f-wrap m-2 w-100 mw-400px">
        {stars > 0 && stars < 6 && (
          <div className="fccc w-100 m-2">
            <h3>Επιπλέον σχόλια (προαιρετικό):</h3>
            <MyTextArea value={extraComments} setValue={setExtraComments} />
          </div>
        )}
      </div>
      {stars > 0 && stars < 6 ? (
        !submitLoading ? (
          <MyButton
            primary={true}
            text={"Υποβολή"}
            onClickFunc={handleSubmit}
            medium={true}
          />
        ) : (
          <ReactLoading type="spin" color="#f3f3f3" height={50} width={50} />
        )
      ) : null}
    </div>
  );
};

export default Review;
