export const stepsLinks = {
  ReserveEidosPlysimatos: "/reserve/eidos-plysimatos/",
  ReservePratirio: "/reserve/epilogi-pratiriou/",
  ReserveDate: "/reserve/imerominia/",
  ReserveInformation: "/reserve/plirofories/",
};

export const stepsLinksSkipPratirio = {
  ReserveEidosPlysimatos: "/reserve-2/eidos-plysimatos/",
  ReserveDate: "/reserve-2/imerominia/",
  ReserveInformation: "/reserve-2/plirofories/",
};

export const checkReservationItem = (
  selectedItem,
  availableItems,
  itemName
) => {
  if (
    !selectedItem ||
    !("id" in selectedItem) ||
    selectedItem.id === undefined
  ) {
    return { err: `no ${itemName} is selected` };
  }
  if (!availableItems.map((item) => item.id).includes(selectedItem.id)) {
    return { err: `selected ${itemName} is invalid` };
  }
  return { err: null };
};

export const checkReservationDate = (selectedItem, availableItems) => {
  if (
    !selectedItem ||
    !("datetime" in selectedItem) ||
    selectedItem.datetime === undefined ||
    !("price" in selectedItem) ||
    selectedItem.price === undefined
  ) {
    return { err: `no date is selected` };
  }
  if (
    !availableItems.map((item) => item.datetime).includes(selectedItem.datetime)
  ) {
    return { err: `selected datetime is invalid` };
  }
  return { err: null };
};

export const IsOkayWashType = (reservation) => {
  if (
    !("wash_type" in reservation) ||
    !("name" in reservation["wash_type"]) ||
    !("id" in reservation["wash_type"]) ||
    !("price" in reservation["wash_type"])
  ) {
    return false;
  }
  return true;
};

export const IsOkayStation = (reservation) => {
  if (
    !("station" in reservation) ||
    !("name" in reservation["station"]) ||
    !("id" in reservation["station"])
  ) {
    return false;
  }
  return true;
};

export const IsOkayDateDatetime = (reservation) => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  if (
    !("date" in reservation) ||
    !("datetime" in reservation["date"]) ||
    !("price" in reservation["date"]) ||
    new Date(reservation.date.datetime).getTime() < today.getTime() // it should be greater than today
  ) {
    return false;
  }
  return true;
};

export const IsOkayDateASAP = (reservation) => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  if (
    !("date" in reservation) ||
    !("as_soon_as_possible" in reservation["date"]) ||
    !("price" in reservation["date"])
  ) {
    return false;
  }
  return true;
};

export const IsOkayDate = (reservation) => {
  return IsOkayDateDatetime(reservation) || IsOkayDateASAP(reservation);
};

export const groupTimeslotsPerDay = (timeslots, start, end) => {
  let timeslotsGrouped = {};
  for (let d = start; d <= end; d = getDateAfterDays(d, 1)) {
    timeslotsGrouped[getDateFormatted(d)] = [];
  }

  for (let timeslot of timeslots) {
    if (!("price" in timeslot) || !("duration" in timeslot)) {
      continue;
    }
    // YYYY/MM/DD format
    let initialDate = timeslot.duration[0].substring(0, 10);
    let key = `${initialDate.split("-")[0]}/${initialDate.split("-")[1]}/${
      initialDate.split("-")[2]
    }`;
    timeslotsGrouped[key].push({
      price: timeslot.price,
      datetime: timeslot.duration[0],
    });
  }
  return timeslotsGrouped;
};

export const getDateAfterDays = (date, numberOfDays) => {
  return new Date(date.getTime() + numberOfDays * 24 * 60 * 60 * 1000);
};

export const getDateFormatted = (date) => {
  return `${date.getFullYear()}/${
    date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }/${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()}`;
};

export const getDatetimeFormatted = (date) => {
  return `${date.getFullYear()}/${
    date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }/${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()} ${
    date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()}`;
};

export const getDatetimeFormattedForFrontend = (date) => {
  return `${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()}-${
    date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }-${date.getFullYear()} ${
    date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()}`;
};

export const getDatetimeFormattedForBackend = (date) => {
  return `${date.getFullYear()}-${
    date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }-${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()} ${
    date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()}:${
    date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds()
  }`;
};

export const getTimeFormatted = (date) => {
  return `${date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()}:${
    date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()
  }`;
};

export const strToDate = (s) => {
  let [date, time] = s.split("T");
  let [year, month, day] = date.split("-");
  let [hour, minute] = time.split(":");
  return new Date(year, month - 1, day, hour, minute);
};

export const removeItemOnce = (arr, value) => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const isDate = (date) => {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};
