export const fullMonths = [
  "Ιανουαρίου",
  "Φεβρουαρίου",
  "Μαρτίου",
  "Απριλίου",
  "Μαΐου",
  "Ιουνίου",
  "Ιουλίου",
  "Αυγούστου",
  "Σεπτεμβρίου",
  "Οκτωβρίου",
  "Νοεμβρίου",
  "Δεκεμβρίου",
];

export const fullWeekDays = [
  "Δευτέρα",
  "Τρίτη",
  "Τετάρτη",
  "Πέμπτη",
  "Παρασκευή",
  "Σάββατο",
  "Κυριακή",
];

export const monthsShorted = [
  "Ιαν.",
  "Φεβ.",
  "Μαρ.",
  "Απρ.",
  "Μαϊ.",
  "Ιουν.",
  "Ιουλ.",
  "Αυγ.",
  "Σεπ.",
  "Οκτ.",
  "Νοε.",
  "Δεκ.",
];

export const weekDaysShorted = [
  "Δε.",
  "Τρ.",
  "Τε.",
  "Πε.",
  "Πα.",
  "Σα.",
  "Κυ.",
];
