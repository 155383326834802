import "./components.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { stepsLinks, stepsLinksSkipPratirio } from "../utils/helper";

const HeaderReservation = ({
  activePage,
  reservation,
  currentStep,
  setCurrentStep,
  stepsIncluded,
  reserveFlow,
}) => {
  const [carWash, setCarWash] = useState("");
  const [pratirio, setPratirio] = useState("");
  const [date, setDate] = useState("");
  const [information, setInformation] = useState("");
  const navigate = useNavigate();

  // there is a better way to do this probably, but I am bored...
  useEffect(() => {
    if (activePage === "ReserveEidosPlysimatos") {
      setCarWash("-selected");
      setPratirio("");
      setDate("");
      setInformation("");
    } else if (activePage === "ReservePratirio") {
      setCarWash("");
      setPratirio("-selected");
      setDate("");
      setInformation("");
    } else if (activePage === "ReserveDate") {
      setCarWash("");
      setPratirio("");
      setDate("-selected");
      setInformation("");
    } else if (activePage === "ReserveInformation") {
      setCarWash("");
      setPratirio("");
      setDate("");
      setInformation("-selected");
    }
  }, [activePage]);

  const stepClicked = (currentStep, setCurrentStep, nextStep) => {
    /* Needs update with the full logic */
    setCurrentStep(nextStep);
    if (reserveFlow === "reserve") {
      navigate(stepsLinks[nextStep]);
    } else if (reserveFlow === "reserve-2") {
      navigate(stepsLinksSkipPratirio[nextStep]);
    }
  };

  return (
    <header className="header frcc w-100 h-80px">
      <div className="header-main frbc">
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/img/logo.png`}
            alt="Μπαράτσας Καύσιμα"
          />
        </Link>
        <ul className="header-steps frcc">
          {stepsIncluded.includes("ReserveEidosPlysimatos") ? (
            <li
              className="frcc"
              onClick={() => {
                stepClicked(
                  currentStep,
                  setCurrentStep,
                  "ReserveEidosPlysimatos"
                );
              }}
            >
              <img
                className="w-22px"
                src={`${process.env.PUBLIC_URL}/img/icons/car-wash${carWash}.png`}
                alt="Car Wash Icon"
              />
              <p className={`header-p${carWash}`}>Είδος πλυσίματος</p>
            </li>
          ) : null}
          {stepsIncluded.includes("ReservePratirio") ? (
            <li
              className="frcc"
              onClick={() => {
                stepClicked(currentStep, setCurrentStep, "ReservePratirio");
              }}
            >
              <img
                className="w-22px"
                src={`${process.env.PUBLIC_URL}/img/icons/location${pratirio}.png`}
                alt="Location Icon"
              />
              <p className={`header-p${pratirio}`}>Επιλογή πρατηρίου</p>
            </li>
          ) : null}
          {stepsIncluded.includes("ReserveDate") ? (
            <li
              className="frcc"
              onClick={() => {
                stepClicked(currentStep, setCurrentStep, "ReserveDate");
              }}
            >
              <img
                className="w-22px"
                src={`${process.env.PUBLIC_URL}/img/icons/calendar${date}.png`}
                alt="Calendar Icon"
              />
              <p className={`header-p${date}`}>Ημερομηνία</p>
            </li>
          ) : null}
          {stepsIncluded.includes("ReserveInformation") ? (
            <li
              className="frcc"
              onClick={() => {
                stepClicked(currentStep, setCurrentStep, "ReserveInformation");
              }}
            >
              <img
                className="w-22px"
                src={`${process.env.PUBLIC_URL}/img/icons/contacts-book${information}.png`}
                alt="Contacts Book Icon"
              />
              <p className={`header-p${information}`}>Στοιχεία</p>
            </li>
          ) : null}
        </ul>
      </div>
    </header>
  );
};

export default HeaderReservation;
