import { useEffect } from "react";
import SuccessPage from "../components/SuccessPage";
import useTitle from "../hooks/useTitle";

const ReserveSuccess = ({ title, setActivePage }) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("Reserve");
  }, [setActivePage]);

  return (
    <SuccessPage
      h2Text="Η κράτηση καταχωρήθηκε επιτυχώς!"
      extraText={
        <p className="text-center">
          Ευχαριστούμε! <br />
          Στα επόμενα λεπτά θα λάβεις email επιβεβαίωσης. <br />
          Μπορείς να κλείσεις την εφαρμογή.
        </p>
      }
    />
  );
};

export default ReserveSuccess;
