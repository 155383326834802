import { useEffect } from "react";
import { Link } from "react-router-dom";
import { UP_TO_TEN_NUMBERS_REGEX } from "../utils/regex";
import MyCheckBox from "./MyCheckbox";
import MyInput from "./MyInput";
import MyRadioInput from "./MyRadioInput";
import MyTextArea from "./MyTextArea";

export const paymentMethods = [
  {
    id: 1,
    name: "Πληρωμή στο πρατήριο",
    price: 0,
  },
  // TODO: uncomment if we do integration with Viva
  // {
  //   id: 2,
  //   name: "Πληρωμή online μέσω Viva",
  //   price: 0,
  // },
];

const InformationForm = ({
  width,
  name,
  setName,
  nameError,
  setNameError,
  phone,
  setPhone,
  phoneError,
  setPhoneError,
  email,
  setEmail,
  emailError,
  setEmailError,
  // licencePlate,
  // setLicencePlate,
  // licencePlateError,
  // setLicencePlateError,
  comments,
  setComments,
  commentsError,
  setCommentsError,
  terms,
  setTerms,
  termsError,
  setTermsError,
  paymentMethod,
  setPaymentMethod,
  paymentMethodError,
  setPaymentMethodError,
}) => {
  useEffect(() => {
    setPaymentMethodError(false);
  }, [paymentMethod, setPaymentMethodError]);

  return (
    <div className={"fcss " + (width >= 1000 ? "w-49" : "w-100")}>
      <h3>Εισάγετε τις πληροφορίες σας:</h3>
      <MyInput
        type="text"
        value={name}
        setValue={setName}
        error={nameError}
        setError={setNameError}
        placeholder="Ονοματεπώνυμο*"
        helpText={
          "Εισάγετε το ονοματεπώνυμό σας με ελληνικούς ή λατινικούς χαρακτήρες."
        }
      />
      <MyInput
        type="tel"
        value={phone}
        setValue={setPhone}
        error={phoneError}
        setError={setPhoneError}
        placeholder="Κινητό τηλέφωνο*"
        allowedCharactersPatern={UP_TO_TEN_NUMBERS_REGEX}
        helpText={"Εισάγετε ένα αποδεκτό 10-ψήφιο αριθμό τηλεφώνου"}
      />
      <MyInput
        type="email"
        value={email}
        setValue={setEmail}
        error={emailError}
        setError={setEmailError}
        placeholder="Email*"
        helpText={"Εισάγετε ένα email, όπως test@example.com"}
      />
      {/* <MyInput
        type="text"
        value={licencePlate}
        setValue={setLicencePlate}
        error={licencePlateError}
        setError={setLicencePlateError}
        placeholder="Πινακίδες οχήματος"
        helpText={"Η πινακίδα του οχήματος πρέπει να είναι έγκυρη."}
      /> */}
      <MyTextArea
        value={comments}
        setValue={setComments}
        error={commentsError}
        setError={setCommentsError}
        placeholder="Επιπλέον σχόλια (προαιρετικό)"
      />
      <MyCheckBox
        value={terms}
        setValue={setTerms}
        label={
          <>
            Αποδέχομαι τους{" "}
            <Link target="_blank" to="/terms">
              Όρους χρήσης
            </Link>
            .
          </>
        }
        error={termsError}
        setError={setTermsError}
        helpText={"Αποδεχτείτε τους όρους για να συνεχίσετε."}
        name="terms_checkbox"
      />
      <h3>Τρόπος Πληρωμής:</h3>
      <ul className="fccc select-ul">
        {paymentMethods.map((method) => {
          return (
            <li
              className="frbc w-100"
              key={method.id}
              onClick={() => setPaymentMethod(method)}
            >
              <div>
                <h3 className="font-medium font-regular">{method.name}</h3>
              </div>
              <div className="frcc">
                <MyRadioInput selected={method.id === paymentMethod.id} />
              </div>
            </li>
          );
        })}
        {paymentMethodError ? (
          <p className="w-100 fcss my-input-help my-input-help-error">
            Επιλέξτε μία από τις μεθόδους πληρωμής.
          </p>
        ) : null}
      </ul>
    </div>
  );
};

export default InformationForm;
