import Modal from "react-bootstrap/Modal";
import MyButton from "./MyButton";

function InformationModal({ title, text, show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="p-20px display-linebreak">{text}</p>
      </Modal.Body>

      <Modal.Footer>
        <MyButton
          secondary={true}
          onClickFunc={handleClose}
          small={true}
          text={"Κλείσιμο"}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default InformationModal;
