import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import useTitle from "../hooks/useTitle";
import { getStationsGivenWashType } from "../api/backendCalls";
import MyRadioInput from "../components/MyRadioInput";
import NextStepReservation from "../components/NextStepReservation";
import { IsOkayStation, IsOkayWashType, stepsLinks } from "../utils/helper";
import useWindowDimensions from "../hooks/useWindowDimensions";

const ReservePratirio = ({
  title,
  setActivePage,
  reservation,
  setReservation,
  nextStep,
}) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("ReservePratirio");
  }, [setActivePage]);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [stations, setStations] = useState(null);
  const [selectedStation, setSelectedStation] = useState(() => {
    if (IsOkayStation(reservation)) {
      return reservation["station"];
    }
    return {};
  });

  useEffect(() => {
    if (!IsOkayWashType(reservation)) {
      navigate(stepsLinks["ReserveEidosPlysimatos"]);
      return;
    }
  }, [reservation, navigate]);

  const getData = async (stations) => {
    let data = await getStationsGivenWashType(reservation.wash_type.id);
    if (JSON.stringify(data) !== JSON.stringify(stations)) {
      setStations(data);
    }
  };

  useEffect(() => {
    if (reservation === {}) {
      return;
    }
    getData(stations);
  }, [reservation, stations]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-100 pb-50px fccc">
      <h2 className="font-bold font-larger step-h">
        Βήμα 2: Επιλογή Πρατηρίου
      </h2>
      <div className="fccc w-100">
        {!stations ? (
          <ReactLoading type="spin" color="#f3f3f3" height={80} width={80} />
        ) : (
          <ul className="fccc select-ul">
            {stations.map((station) => {
              return (
                <li
                  className="frbc w-100"
                  key={station.id}
                  onClick={() => setSelectedStation(station)}
                >
                  <div>
                    <h3 className="font-medium font-regular">{station.name}</h3>
                    <h4 className="font-small font-regular">
                      <a
                        className="blue-text-color font-regular font-small no-decoration"
                        href={station.google_maps_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Δες στο χάρτη
                      </a>
                    </h4>
                  </div>
                  <div className="frcc">
                    {reservation && "wash_type" in reservation ? (
                      <p className="font-medium font-bold">
                        {parseFloat(station.price_after_discounts).toFixed(
                          0
                        ) !==
                        parseFloat(reservation.wash_type.price).toFixed(0) ? (
                          <>
                            {parseFloat(station.price_after_discounts).toFixed(
                              0
                            )}
                            -
                            {parseFloat(reservation.wash_type.price).toFixed(0)}{" "}
                            €
                          </>
                        ) : (
                          <>
                            {parseFloat(reservation.wash_type.price).toFixed(0)}{" "}
                            €
                          </>
                        )}
                      </p>
                    ) : (
                      <></>
                    )}
                    <MyRadioInput
                      selected={station.id === selectedStation.id}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      {width < 1000 ? (
        // phone view
        <NextStepReservation
          show={true}
          upRowTextMain={
            reservation !== {} &&
            "wash_type" in reservation &&
            "name" in reservation.wash_type
              ? Object.keys(selectedStation).length !== 0
                ? `${selectedStation.name}, ${reservation.wash_type.name}`
                : `${reservation.wash_type.name}`
              : ""
          }
          downRowTextMain={
            reservation !== {} &&
            "wash_type" in reservation &&
            "price" in reservation.wash_type
              ? parseFloat(reservation.wash_type.price).toFixed(2) + " €"
              : "20.00 €"
          }
          onClickFunc={() =>
            nextStep(
              selectedStation,
              stations,
              reservation,
              setReservation,
              navigate
            )
          }
          disabledButton={Object.keys(selectedStation).length === 0}
        />
      ) : (
        // desktop view
        <NextStepReservation
          show={true}
          upRowTextMain={
            reservation !== {} &&
            "wash_type" in reservation &&
            "name" in reservation.wash_type
              ? Object.keys(selectedStation).length !== 0
                ? `${selectedStation.name}, ${reservation.wash_type.name}`
                : `${reservation.wash_type.name}`
              : ""
          }
          upRowTextSecondary={
            reservation !== {} &&
            "wash_type" in reservation &&
            "price" in reservation.wash_type
              ? parseFloat(reservation.wash_type.price).toFixed(2) + " €"
              : "20.00 €"
          }
          downRowTextSecondary={"Η τιμή θα καθοριστεί σε επόμενο βήμα"}
          nextButtonText={"Επόμενο"}
          onClickFunc={() =>
            nextStep(
              selectedStation,
              stations,
              reservation,
              setReservation,
              navigate
            )
          }
          disabledButton={Object.keys(selectedStation).length === 0}
        />
      )}
    </div>
  );
};

export default ReservePratirio;
