const SuccessPage = ({ h2Text, extraText }) => {
  return (
    <div className="full-page fccc w-100">
      <img
        className="w-90px"
        src={`${process.env.PUBLIC_URL}/img/success.gif`}
        alt="tick gif"
      />
      <h2 className="font-bold font-large text-center">{h2Text}</h2>
      {extraText}
    </div>
  );
};

export default SuccessPage;
