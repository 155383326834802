import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import useTitle from "../hooks/useTitle";
import { getWashTypes } from "../api/backendCalls";
import MyRadioInput from "../components/MyRadioInput";
import InformationModal from "../components/InformationModal";
import NextStepReservation from "../components/NextStepReservation";
import { IsOkayWashType } from "../utils/helper";
import useWindowDimensions from "../hooks/useWindowDimensions";

const ReserveEidosPlysimatos = ({
  title,
  setActivePage,
  reservation,
  setReservation,
  nextStep,
}) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("ReserveEidosPlysimatos");
  }, [setActivePage]);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [washTypes, setWashTypes] = useState(null);
  const [shouldDisplayIsRecommended, setShouldDisplayIsRecommended] = useState(null);
  const [selectedWashType, setSelectedWashType] = useState(() => {
    if (IsOkayWashType(reservation)) {
      return reservation["wash_type"];
    }
    return {};
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (washType) => {
    setSelectedWashType(washType);
    setShow(true);
  };

  const getData = async (washTypes) => {
    let data = await getWashTypes();
    if (JSON.stringify(data) !== JSON.stringify(washTypes)) {
      setWashTypes(data);
    }
  };

  useEffect(() => {
    getData(washTypes);
  }, [washTypes]);

  useEffect(() => {
    if (shouldDisplayIsRecommended !== null) {
      return
    }
    // 50% chance of displaying it
    setShouldDisplayIsRecommended(Math.random() > 0.5)
  }, [shouldDisplayIsRecommended])

  return (
    <div className="w-100 pb-50px fccc">
      <h2 className="font-bold font-larger step-h">Βήμα 1: Είδος Πλυσίματος</h2>
      <div className="fccc w-100">
        {!washTypes ? (
          <ReactLoading type="spin" color="#f3f3f3" height={80} width={80} />
        ) : (
          <ul className="fccc select-ul">
            {washTypes.map((washType) => {
              return (
                <li
                  className="frbc w-100"
                  key={washType.id}
                  onClick={() => setSelectedWashType(washType)}
                >
                  <div>
                    <h3 className="font-medium font-regular">
                      <span>{washType.name}</span>
                      {(washType.is_recommended === true && shouldDisplayIsRecommended) ? <span className="is-recommended font-small">Δημοφιλές🔥</span> : <></>}
                    </h3>
                    <h4 className="font-small font-regular">
                      {washType.duration} λεπτά{" "}
                      <span
                        className="font-bold"
                        onClick={() => handleShow(washType)}
                      >
                        Λεπτομέρειες
                      </span>
                    </h4>
                  </div>
                  <div className="frcc">
                    <p className="font-medium font-bold">
                      {parseFloat(washType.price_after_discounts).toFixed(0) !==
                        parseFloat(washType.price).toFixed(0) ? (
                        <>
                          {parseFloat(washType.price_after_discounts).toFixed(
                            0
                          )}
                          -{parseFloat(washType.price).toFixed(0)} €
                        </>
                      ) : (
                        <>{parseFloat(washType.price).toFixed(0)} €</>
                      )}
                    </p>
                    <MyRadioInput
                      selected={washType.id === selectedWashType.id}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {width < 1000 ? (
        // phone view
        <NextStepReservation
          show={selectedWashType.id !== undefined}
          upRowTextMain={selectedWashType.name}
          downRowTextMain={parseFloat(selectedWashType.price).toFixed(2) + " €"}
          onClickFunc={() =>
            nextStep(
              selectedWashType,
              washTypes,
              reservation,
              setReservation,
              navigate,
              shouldDisplayIsRecommended,
            )
          }
        />
      ) : (
        <NextStepReservation
          // desktop view
          show={selectedWashType.id !== undefined}
          upRowTextMain={selectedWashType.name}
          upRowTextSecondary={
            parseFloat(selectedWashType.price).toFixed(2) + " €"
          }
          downRowTextSecondary={"Η τελική τιμή θα καθοριστεί σε επόμενο βήμα"}
          nextButtonText={"Επόμενο"}
          onClickFunc={() =>
            nextStep(
              selectedWashType,
              washTypes,
              reservation,
              setReservation,
              navigate,
              shouldDisplayIsRecommended,
            )
          }
        />
      )}

      <InformationModal
        text={selectedWashType.description}
        title={selectedWashType.name}
        show={show}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ReserveEidosPlysimatos;
