import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "./Footer";

const Layout = ({ activePage, footer }) => {
  return (
    <>
      <Header activePage={activePage} />
      <main className="content fccc">
        <Outlet />
      </main>
      {footer ? <Footer /> : null}
    </>
  );
};

export default Layout;
