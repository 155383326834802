import { useEffect, useState } from "react";
import { monthsShorted, weekDaysShorted } from "../utils/greekLanguage";
import { getDateAfterDays, getDateFormatted } from "../utils/helper";

const calculateDates = (minDay, maxDay) => {
  let minDate = new Date(minDay);
  minDate.setHours(0, 0, 0, 0);
  let maxDate = new Date(maxDay);
  maxDate.setHours(0, 0, 0, 0);
  let newDates = [];
  for (let i = minDate; i <= maxDate; i = getDateAfterDays(i, 1)) {
    newDates.push(i);
  }
  return newDates;
};

const DatesList = ({
  selectedDate,
  setSelectedDate,
  minDay,
  maxDay,
  daysToDisplay,
  minCosts,
}) => {
  const [dates] = useState(calculateDates(minDay, maxDay));

  const [displayedDates, setDisplayedDates] = useState([0, daysToDisplay]);

  // moving automatically the displayed dates
  useEffect(() => {
    // handle edge case left
    if (
      selectedDate.getDate() === dates[0].getDate() ||
      selectedDate.getDate() === dates[1].getDate()
    ) {
      setDisplayedDates([0, daysToDisplay]);
      return;
    }
    // handle edge case right
    if (
      selectedDate.getDate() === dates[dates.length - 1].getDate() ||
      selectedDate.getDate() === dates[dates.length - 2].getDate()
    ) {
      setDisplayedDates([dates.length - daysToDisplay, dates.length]);
      return;
    }
    for (let i = 0; i < dates.length - 2; i++) {
      if (
        dates[i + 2].getDate() === selectedDate.getDate() &&
        dates[i + 2].getMonth() === selectedDate.getMonth()
      ) {
        setDisplayedDates([i, i + daysToDisplay]);
        return;
      }
    }
  }, [selectedDate, dates, daysToDisplay]);

  const onClickFunc = (add) => {
    if (displayedDates[0] === 0 && add === -1) return;
    if (displayedDates[1] === dates.length && add === 1) return;
    setDisplayedDates([displayedDates[0] + add, displayedDates[1] + add]);
  };

  return (
    <div className="w-100 frac">
      <img
        src={`${process.env.PUBLIC_URL}/img/icons/right-arrow-light-gray.png`}
        className={
          "left-button-date " +
          (displayedDates[0] === 0 ? "button-disabled" : "")
        }
        alt="next"
        onClick={() => onClickFunc(-1)}
      />
      <div className="frcc">
        {dates.slice(displayedDates[0], displayedDates[1]).map((date) => {
          return (
            <div
              onClick={() => setSelectedDate(date)}
              className={
                "date-div " +
                (getDateFormatted(date) === getDateFormatted(selectedDate)
                  ? "date-div-selected"
                  : "")
              }
              key={date.getDate()}
            >
              <h4 className="text-center">
                {weekDaysShorted[(date.getDay() + 6) % 7]}
              </h4>
              <h5 className="text-center">
                {date.getDate()} {monthsShorted[date.getMonth()]}
              </h5>
              <p className="text-center font-small italic">από</p>
              <h3 className="text-center blue-text-color font-bold italic">
                {minCosts && getDateFormatted(date) in minCosts
                  ? minCosts[getDateFormatted(date)] + "€"
                  : "-"}
              </h3>
            </div>
          );
        })}
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/img/icons/right-arrow-light-gray.png`}
        className={
          "right-button-date " +
          (displayedDates[1] === dates.length ? "button-disabled" : "")
        }
        alt="next"
        onClick={() => onClickFunc(1)}
      />
    </div>
  );
};

export default DatesList;
