import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import useTitle from "../hooks/useTitle";
import {
  getTimeFormatted,
  IsOkayDate,
  IsOkayDateASAP,
  IsOkayDateDatetime,
  IsOkayStation,
  IsOkayWashType,
  stepsLinks,
} from "../utils/helper";
import useWindowDimensions from "../hooks/useWindowDimensions";
import MyButton from "../components/MyButton";
import { fullMonths, fullWeekDays } from "../utils/greekLanguage";
import InformationForm, { paymentMethods } from "../components/InformationForm";
import {
  EMAIL_REGEX,
  GREEK_OR_ENGLISH_REGEX,
  GREEK_OR_ENGLISH_REGEX_0_OR_MORE,
  TEN_NUMBERS_ONLY_REGEX,
} from "../utils/regex";
import { createReservation } from "../api/backendCalls";

const ReserveInformation = ({
  title,
  setActivePage,
  reservation,
  setReservation,
}) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("ReserveInformation");
  }, [setActivePage]);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  // const [licencePlate, setLicencePlate] = useState("");
  // const [licencePlateError, setLicencePlateError] = useState(false);

  const [comments, setComments] = useState("");
  const [commentsError, setCommentsError] = useState(false);

  const [terms, setTerms] = useState("");
  const [termsError, setTermsError] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState(false);

  const [creatingReservationLoading, setCreatingReservationLoading] =
    useState(false);

  useEffect(() => {
    if (!IsOkayWashType(reservation)) {
      navigate(stepsLinks["ReserveEidosPlysimatos"]);
      return;
    }
    if (!IsOkayStation(reservation)) {
      navigate(stepsLinks["ReservePratirio"]);
      return;
    }
    if (!IsOkayDate(reservation)) {
      navigate(stepsLinks["ReserveDate"]);
      return;
    }
  }, [reservation, navigate]);

  const handleSubmit = async () => {
    let error = false;
    if (!GREEK_OR_ENGLISH_REGEX.test(name)) {
      setNameError(true);
      error = true;
    }
    if (!TEN_NUMBERS_ONLY_REGEX.test(phone)) {
      setPhoneError(true);
      error = true;
    }
    if (!EMAIL_REGEX.test(email)) {
      setEmailError(true);
      error = true;
    }
    if (!GREEK_OR_ENGLISH_REGEX_0_OR_MORE.test(comments)) {
      setCommentsError(true);
      error = true;
    }
    // if (!UP_TO_TEN_CHARACTERS_REGEX.test(licencePlate)) {
    //   setLicencePlateError(true);
    //   error = true;
    // }
    if (!terms) {
      setTermsError(true);
      error = true;
    }
    if (
      !paymentMethod ||
      Object.keys(paymentMethod).length === 0 ||
      !paymentMethods.map((i) => i.id).includes(paymentMethod.id)
    ) {
      setPaymentMethodError(true);
      error = true;
    }
    if (error) {
      console.log("error");
      return;
    }

    // create reservation in backend
    setCreatingReservationLoading(true);

    reservation.information = {
      name: name,
      phone: phone,
      email: email,
      // licence_plate: licencePlate,
      payment_method: paymentMethod,
      comments: comments,
    };
    reservation.displayed_final_price = parseInt(
      document.getElementById("total-price").innerHTML
    );
    setReservation(reservation);

    let data = await createReservation(reservation);
    if (data === null) {
      console.log("server error");
      alert(
        "Συγγνώμη, αλλά η ώρα του πλυσίματος που επιλέξατε δεν είναι πλέον διαθέσιμη. Ξαναπροσπαθήστε από την αρχή."
      );
      setReservation({});
      navigate(stepsLinks["ReserveEidosPlysimatos"]);
      setCreatingReservationLoading(false);
      return;
    }
    setReservation({});
    setCreatingReservationLoading(false);
    navigate("/reserve/success/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="fccc w-100 pb-50px">
      <h2 className="font-bold font-larger step-h">
        Βήμα 4: Πληροφορίες κράτησης
      </h2>
      <div
        className={
          "information-form w-100 " + (width >= 1000 ? "frbs" : "fccc")
        }
      >
        {width >= 1000 ? (
          <InformationForm
            width={width}
            name={name}
            setName={setName}
            nameError={nameError}
            setNameError={setNameError}
            phone={phone}
            setPhone={setPhone}
            phoneError={phoneError}
            setPhoneError={setPhoneError}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            // licencePlate={licencePlate}
            // setLicencePlate={setLicencePlate}
            // licencePlateError={licencePlateError}
            // setLicencePlateError={setLicencePlateError}
            comments={comments}
            setComments={setComments}
            commentsError={commentsError}
            setCommentsError={setCommentsError}
            terms={terms}
            setTerms={setTerms}
            termsError={termsError}
            setTermsError={setTermsError}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            paymentMethodError={paymentMethodError}
            setPaymentMethodError={setPaymentMethodError}
          />
        ) : null}
        <div className={width >= 1000 ? "w-49" : "w-90"}>
          {IsOkayStation(reservation) &&
          IsOkayWashType(reservation) &&
          IsOkayDate(reservation) ? (
            <div className="w-100 fcss information-from-steps">
              {IsOkayDateDatetime(reservation) ? (
                <h3>
                  <span>
                    {
                      fullWeekDays[
                        (new Date(reservation["date"]["datetime"]).getDay() +
                          6) %
                          7
                      ]
                    }{" "}
                    {new Date(reservation["date"]["datetime"]).getDate()}{" "}
                    {
                      fullMonths[
                        new Date(reservation["date"]["datetime"]).getMonth()
                      ]
                    }{" "}
                    {new Date(reservation["date"]["datetime"]).getFullYear()},{" "}
                    {getTimeFormatted(
                      new Date(reservation["date"]["datetime"])
                    )}
                  </span>
                </h3>
              ) : IsOkayDateASAP(reservation) ? (
                <>
                  <h4>
                    <span>Το συντομότερο δυνατόν</span>
                  </h4>
                  <p className="my-input-help">
                    Κατά την άφιξή σου στο κατάστημα, θα πρέπει να
                    επικοινωνήσεις με τους υπαλλήλους του καταστήματος σχετικά
                    με το πότε μπορείς να εξυπηρετηθείς. Θα γίνει προσπάθεια να
                    εξυπηρετηθείς το συντομότερο δυνατόν!
                  </p>
                </>
              ) : null}
              <h3>
                <span>Πρατήριο:</span> {reservation.station.name}
              </h3>
              <h3>
                <span>Είδος πλυσίματος:</span> {reservation.wash_type.name}
              </h3>
              <h3>
                <span>Τιμή:</span> {reservation.date.price} €
              </h3>
            </div>
          ) : null}
          {width < 1000 ? (
            <>
              <hr />
              <InformationForm
                width={width}
                name={name}
                setName={setName}
                nameError={nameError}
                setNameError={setNameError}
                phone={phone}
                setPhone={setPhone}
                phoneError={phoneError}
                setPhoneError={setPhoneError}
                email={email}
                setEmail={setEmail}
                emailError={emailError}
                setEmailError={setEmailError}
                // licencePlate={licencePlate}
                // setLicencePlate={setLicencePlate}
                // licencePlateError={licencePlateError}
                // setLicencePlateError={setLicencePlateError}
                comments={comments}
                setComments={setComments}
                commentsError={commentsError}
                setCommentsError={setCommentsError}
                terms={terms}
                setTerms={setTerms}
                termsError={termsError}
                setTermsError={setTermsError}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                paymentMethodError={paymentMethodError}
                setPaymentMethodError={setPaymentMethodError}
              />
            </>
          ) : null}
        </div>
      </div>
      {!creatingReservationLoading ? (
        <>
          {IsOkayDate(reservation) ? (
            <h3 className="summary">
              Σύνολο: <span id="total-price">{reservation.date.price}</span> €
            </h3>
          ) : null}
          <MyButton
            primary={true}
            big={true}
            text="Ολοκλήρωση κράτησης"
            onClickFunc={handleSubmit}
          />
        </>
      ) : (
        <ReactLoading type="spin" color="#f3f3f3" height={50} width={50} />
      )}
    </div>
  );
};

export default ReserveInformation;
