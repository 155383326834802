import { useEffect } from "react";
import useTitle from "../hooks/useTitle";

const Terms = ({ title, setActivePage }) => {
  useTitle({ title });

  useEffect(() => {
    setActivePage("Terms");
  }, [setActivePage]);

  return (
    <div className="full-page fccc mw-600px p-20px">
      <h2 className="font-bold font-larger text-center">Όροι Χρήσης</h2>
      <h3 className="font-bold font-medium text-center">
        Σχετικά με τους όρους χρήσης
      </h3>
      <p className="text-center">
        Ευχαριστούμε που χρησιμοποιείτε τις υπηρεσίες και τα προϊόντα μας. Οι
        πληροφορίες που δημοσιεύονται στην ιστοσελίδα mparatsas.gr, αποτελούν
        ιδιοκτησία της εταιρείας Μπαράτσας Α.Ε. και των εκπροσώπων της, και
        προστατεύονται από την Ελληνική και Διεθνή Νομοθεσία περί Πνευματικών
        Δικαιωμάτων. Καταπάτηση των πνευματικών δικαιωμάτων της Μπαράτσας Α.Ε.,
        καθώς και των λοιπών αποκλειστικών της δικαιωμάτων νομιμοποιεί η
        Μπαράτσας Α.Ε. να κινηθεί ποινικά κατά του παραβάτη.
      </p>
      <h3 className="font-bold font-medium text-center">
        Πνευματικά Δικαιώματα
      </h3>
      <p className="text-center">
        Οι πληροφορίες και τα γραφικά που δημοσιεύονται στο δικτυακό τόπο της
        Μπαράτσας Α.Ε. απαγορεύεται να αντιγραφούν, αναδημοσιευτούν, ή να
        αναπαραχθούν ολόκληρα ή εν μέρει και για οποιαδήποτε χρήση, χωρίς
        έγγραφη άδεια της Μπαράτσας Α.Ε. Στην ιστοσελίδα mparatsas.gr
        περιλαμβάνονται υλικό, Trademarks, Service Marks, Φωτογραφίες και άλλο
        περιεχόμενο που ανήκει στη Μπαράτσας Α.Ε. και προστατεύεται από το Νόμο.
        Όλα τα περιεχόμενα του site (συμπεριλαμβανομένων Κειμένων, Γραφικών,
        Εικόνων, Φωτογραφιών και Λογισμικού) είναι πνευματική ιδιοκτησία της
        Μπαράτσας Α.Ε. και προστατεύονται από την Ελληνική και Διεθνή Νομοθεσία.
        Απαγορεύεται η οποιαδήποτε αντιγραφή, αναπαραγωγή και αναμετάδοση του
        υλικού, χωρίς έγγραφη άδεια της εταιρείας.
      </p>
      <h3 className="font-bold font-medium text-center">Πολιτική Απορρήτου</h3>
      <p className="text-center">
        Με την χρήση των υπηρεσιών της Μπαράτσας Α.Ε., μας εμπιστεύεστε ορισμένα
        στοιχεία. Χρησιμοποιώντας τις υπηρεσίες μας, θα θέλαμε να ξέρετε τον
        τρόπο με το οποίο κάνουμε χρήση αυτών των στοιχείων. Αν χρησιμοποιείτε
        τις υπηρεσίες μας αφιερώστε λίγο χρόνο για να ενημερωθείτε πάνω στην
        Πολιτική Απορρήτου. Τα στοιχεία που συλλέγουμε είναι τα απαραίτητα, για
        να μπορέσουμε να κάνουμε κρατήσεις πλυσίματος και να γίνει με τον
        καλύτερο δυνατό τρόπο η παροχή αυτής της υπηρεσίας. Κατα την κράτηση
        ενός πλυσίματος, απαιτείται να μας δώσετε στοιχεία όπως το email σας, το
        ονοματεπώνυμό σας, το κινητό σας κτλ. Η Μπαράτσας Α.Ε. δεν θα
        δημοσιεύσει τα στοιχεία σας σε οποιαδήποτε τρίτη εταιρία. Τα στοιχεία
        αυτά χρησιμοποιούνται μόνο για την καταγραφή της κράτησης πλυσίματός
        σας. Εκτός απο αυτό, η Μπαράτσας Α.Ε. έχει το δικαίωμα να χρησιμοποιήσει
        το email σας ή το κινητό σας για να σας αποστείλει προσφορές. Σε
        περίπτωση που δεν θέλετε να συνεχίσουν να σας στέλνονται προσφορές, θα
        μπορείτε να κάνετε απεγγραφή από κάποιο συγκεκριμένο link. Για
        οποιαδήποτε απορία μπορείτε να επικοινωνήσετε μαζί μας.
      </p>
      <h3 className="font-bold font-medium text-center">Cookies</h3>
      <p className="text-center">
        Το cookie είναι ένα μικρό αρχείο που περιέχει μια συμβολοσειρά
        χαρακτήρων και το οποίο αποστέλλεται στον υπολογιστή σας όταν
        επισκέπτεστε τον ιστότοπο μας. Όταν επισκεφτείτε τον ίδιο ιστότοπο τότε
        το cookie δίνει την δυνατότητα στον συγκεκριμένο ιστότοπο να αναγνωρίζει
        το πρόγραμμα περιήγησης (browser) που χρησιμοποιείτε. Τα cookie
        ενδέχεται να αποθηκεύουν προτιμήσεις χρηστών και άλλες πληροφορίες.
        Μπορείτε να επαναφέρετε το πρόγραμμα περιήγησης, προκειμένου να
        αποκλείσετε όλα τα cookie ή να υποδείξετε το χρόνο αποστολής κάποιου
        cookie. Ωστόσο, ορισμένες υπηρεσίες ή δυνατότητες ιστότοπων ενδέχεται να
        μη λειτουργούν σωστά χωρίς cookie.
      </p>
    </div>
  );
};

export default Terms;
